* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  overflow-y: scroll;
  /* position: relative; */
}

body {
  padding-top: 80px; /* This should be equal to the height of your header */
  background-color: #e9e9e9 !important;
  color: #f2f0e4 !important;
}

/* linear-gradient(    to right,    rgb(13, 110, 253) 0%,    rgb(14, 53, 120) 100%  ) !important */

#logo {
  width: 116px;
}

.priceNavbar {
  padding: 0 !important;
  background-color: #035a0a;
}

.tradePriceContainer {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}

.modal-content {
  /* border: 4px solid #db941a !important; */
  box-shadow: 0px 2px 5px #464343;
}

.modal-header {
  color: #313030 !important;
}

.modal-body {
  color: #313030 !important;
}

.dynamicNavbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
}

.instaLoader {
  display: block;
}
.backgroundAnimation {
  height: 4px;
  background: #27c4f5
    linear-gradient(to right, yellow, red, rgb(82, 0, 82), rgb(48, 48, 240));
  background-size: 500%;
  -webkit-animation: 2s linear infinite barprogress, 0.3s fadein;
  animation: 2s linear infinite barprogress, 0.3s fadein;
  width: 100%;
}
@-webkit-keyframes barprogress {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}
@keyframes barprogress {
  0% {
    background-position: 125% 0;
  }
  to {
    background-position: 0% 0;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.absoluteTop {
  position: absolute;
  top: 0;
  left: 0;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  background-color: #fff;
}

/* ============== Loader ================================== */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: -15px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 32px;
  left: 37px;
  width: 2px;
  height: 3px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ================================================ */
/* DOT LOADER */
/* ================================================ */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #cbe596;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#statusStyle {
  color: rgb(211, 175, 16);
}

/* ============= Top Nav bar ===================== */

#accountDropDown {
  background-color: transparent;
  border: none;
  color: #3d3d3d;
}

.topNavLinkWrapper {
  width: 100%;
  height: 100%;
}

.topNavContainer {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#topNavLink {
  text-decoration: none;
  font-weight: 500;
}

#avatar {
  height: 32px;
  width: 32px;
  margin-top: 5px;
  cursor: pointer;
}

#backHomeLink {
  text-decoration: none;
  color: #696666;
}

.noDecoration {
  text-decoration: none;
}

.nav-grad {
  /* background-image: linear-gradient(90deg,#0a1992 0,#000537)!important; */

  /* background-color: rgb(14, 53, 120); */

  /* padding: 0 !important; */

  box-shadow: 0px 1px 4px gray;
  background-color: white;
  padding-left: 54px !important;
  padding-right: 54px !important;
}

.loggedNav {
  display: flex;
  justify-content: space-around;
  width: 40%;
  height: 60px !important;
  align-items: center;
  color: #fff;
}

#loggedLink {
  color: #3d3d3d;
  text-decoration: none;
  display: block;
}

#loggedLink:hover {
  /* color: white !important; */
}

a.dropdown-item a {
  color: gray !important;
}

a.dropdown-item {
  color: gray !important;
}

a.dropdown-item:hover {
  background-color: #f28705 !important;
  color: white !important;
}

a.dropdown-menu:hover {
  color: white !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("./assets/menu.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

/* ================= Banner Section ================================= */
#bannerImg {
  height: auto;
  width: 90%;
}

.textColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bannerText {
  margin-left: 1.6rem;
}

#bannerText1 {
  font-size: 3.125rem;
  font-weight: 700;
  color: #f24405;
}

#bannerText2 {
  color: #f2f0e4;
}

#startBtn {
  width: 40%;
  height: 2.5rem;
  border: none;
  background-color: #f28705;
  color: #fff;
  font-weight: 600;
  margin-left: 1.6rem;
  margin-top: 1.25rem;
  border-radius: 0.3rem;
}

/* =================== About Section ======================= */
.aboutSection {
  padding-top: 1.2rem;
}

.aboutText {
  text-align: center;
  font-weight: 600;
}

#aboutText1 {
  color: #ffa63c;
}

#aboutText2 {
  color: #ffa63c;
}

#aboutTextParagraph {
  line-height: 33px;
}

#aboutImg {
  height: 100%;
  width: 100%;
  color: #f2f0e4;
}

/* ===================== Why Choose us ================================ */
/* .chooseUsSection {
  padding-top: 20px;
} */

.whyChooseUsText {
  margin: 40px 0;
  text-align: center;
}

.chooseUs {
  background-color: #212529a3;
  font-size: 1rem;
  padding: 2.1rem;
  text-align: center;
  border-radius: 5px;
  color: #f2f0e4;
}

#chooseUsIcon {
  width: 40px;
  height: 40px;
}

#chooseUsTitle {
  color: #f25c05;
}

/* ================ Investment Plan Section ====================== */
#InvestmentPlans {
  margin: 40px 0;
  padding-bottom: 0;
}

.planCard {
  height: 400px;
  background-color: #212529a3;
  font-size: 1rem;
  padding: 2.1rem;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 2px 8px #edb267;
  transition: transform 0.3s;
}

.planCard:hover {
  transform: translateY(-1.3rem) scale(1);
}

.planCard h2 {
  color: #f24405;
  font-family: monospace;
}

.planCard span {
  color: #c5c5c5;
  font-weight: 600;
}

.planCard h3 {
  color: #edb267;
}

.plansRow {
  padding: 40px 0;
}

.startPlanBtn {
  background-color: #f28705;
  width: 70%;
  height: 2.5rem;
  color: #fff;
  font-weight: 600;
  margin-top: 1.25rem;
  border: none;
  border-radius: 0.3rem;
}

.startPlanBtn:hover {
  background-color: #db941a;
}

/* ============== Testimony Section ============================ */
#testimonyCard {
  margin: 10px 0;
  height: 310px;
  text-align: center;
  padding: 16px;
}

#testimonyImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.testimonyText {
  padding-bottom: 40px;
}

/* ============= Contact Info ======================== */

#contactUs {
  margin-top: 60px;
}

.contactContainer {
  padding-top: 60px;
}

.contactRow {
  margin: 90px;
}

.contactInfo {
  width: 100%;
  height: 375px;
  background-color: #f28705;
  padding: 40px;
}

#contactBtn {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #f28705;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

.contactWrapper {
  padding: unset;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactWrapper li {
  list-style: none;
  color: #fff;
}

.contactWrapper li span {
  margin-left: 20px;
}

#contactIcon {
  width: 32px;
  height: auto;
}

#contactLabel {
  color: #f28705;
  font-size: 20px;
  font-weight: 400;
}

/* ============= Sponsors =================== */

.center {
  display: flex;
  justify-content: center;
}

#sponsors {
  width: 120px;
  height: auto;
  filter: grayscale(100%);
}

/* =============== Footer =============== */
.footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;
  position: absolute;
  bottom: 0;
}

.homeFooter {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;
}

#footerLogo {
  color: #f24405;
}

/* ============= popOver =================== */
.popingCard {
  width: 280px;
  padding: 10px 28px;
  background-color: #f24405;
  color: #fff;
  border-radius: 5px;
  margin: 0 !important;
  position: fixed;
  top: 80%;
  left: 76%;
}

.pop {
  display: unset;
}

.unpop {
  display: none;
}

#popupCountry {
  font-weight: 800;
  /* color: #f2f0e4; */
}

/* ================= Login ====================== */

.formContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.formHolder {
  padding: 20px;
  padding-top: 15px;
  width: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 7px #bebebe;
  color: gray;
}

#formLabel {
  color: #976d04;
  font-weight: 500;
}

#formTitle {
  color: #252525;
  margin-bottom: 30px;
}

#logBtn {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border: none;
  background-image: linear-gradient(
    to right,
    rgb(13, 110, 253) 0%,
    rgb(19, 65, 143) 100%
  ) !important;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

#actionBtn {
  width: 80px;
  height: 45px;
  border: none;
  border-radius: 5px;
  background-color: green;
  color: #fff;
  font-weight: 400;
  font-family: monospace;
}

/* ========== Captcher ============= */
.captchaBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #2ebea1;
  margin-bottom: 10px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 25px;
}

.captchaContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#code1 {
  rotate: 14deg;
}

#code2 {
  rotate: -47deg;
}

#code3 {
  rotate: -7deg;
}

#code4 {
  rotate: 42deg;
}

#code5 {
  rotate: 20deg;
}

#code6 {
  rotate: 4deg;
}

.signupWrapper {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.signUp {
  width: 50%;
  padding: 20px;
  margin: 20px 0;
  background-color: #fff;
  color: gray;
  box-shadow: 0px 0px 7px #bebebe;
}

#resetform {
  height: 250px;
}

#restpassword {
  height: 75vh;
}

/* ============== Dashboard ======================== */

.dashboard {
  margin-top: 20px;
}

.dashboardCard {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 0px 3px 0px #cfcccc;
  color: #484545;
}

.clipSection {
  display: flex;
  margin-bottom: 40px;
}

.clipboardinput {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border: none;
  outline: 1px solid #2ebea1;
  background-color: #2ebea1;
  color: rgb(255 255 255);
}

.clipIconHolder {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #042d2a;
  outline: 1px solid #042d2a;
  cursor: pointer;
}

#clipIcon {
  width: 32px;
  height: 32px;
}

#dashboardLabel {
  font-size: 19px;
  font-weight: 500;
  color: #976d04;
  padding-top: 20px !important;
}

.tableWrapper {
  overflow-x: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  color: rgb(48 47 47);
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px 15px;
  white-space: nowrap;
}

th {
  background-color: #000000a3;
  color: #fff;
}
tr {
  background-color: #a7a4a4;
}

tr:nth-child(even) {
  background-color: #c1bcbc;
}

tr#userTableData {
  background-color: white !important;
}

tr#userTableData:nth-child(even) {
  background-color: #caeefc;
}

td#tableData {
  font-size: 14px;
  color: #4b4747;
  padding: 10px 20px;
}

.table-responsive {
  padding-bottom: 60px;
}

.referralPage {
  height: 80vh;
  padding-top: 30px !important;
}

#deleteBtn {
  cursor: pointer;
  padding: 6px;
  background-color: red;
}

#deleteBtn:hover {
  background-color: rgb(148, 8, 8);
}

#updateBtn {
  cursor: pointer;
  padding: 6px;
  background-color: rgb(255, 187, 0);
  color: #fff;
}

#updateBtn:hover {
  background-color: rgb(219, 163, 9);
}

#referrenceID {
  color: #006aff;
}

#plusAmount {
  color: rgb(10, 148, 17);
}

.contactWrapper li {
  display: flex;
}

/* ============== Profile ========================== */
.profile {
  background-color: #fff;
  width: 70%;
  padding: 25px;
  box-shadow: 0px 0px 7px #bebebe;
}

#cantChange {
  outline: none;
  border: none;
  background-color: #2ebea1;
  color: #fff;
}

#sideNote {
  color: #757272;
  font-size: 14px;
}

#clipwrapper {
  margin-bottom: 20px;
}

/* ================= Avatar ===================== */

.avatarWrapper {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.addProfile {
  position: absolute;
  bottom: 4%;
  left: 57%;
}

.avatar {
  width: 180px;
  height: 100%;
  background-color: #edeeee;
  border-radius: 100%;
  /* border: 2px solid greenyellow; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: ease-in 0.5s;
}

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

#profileImage {
  display: none !important;
}

.editIcon {
  width: 50%;
}

/* =========== notification =================== */

#alert {
  padding: 12px;
}

.notification {
  width: 270px;
  position: fixed;
  top: 12%;
  left: 50%;
  z-index: 1;
  animation: moveInTop 1s;
}

#google_translate_element select {
  padding: 10px 16px;
  margin-left: 3px;
  background-color: transparent;
  border: 1px solid #f24405;
  border-radius: 5px;
  color: #504f4f;
}
#google_translate_element div {
  font-size: 0;
  margin-top: 11px;
}
#google_translate_element span {
  display: none !important;
}

.butt {
  background-color: #035a0a;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 15px !important;
  padding: 4px 6px;
  width: 100px;
  height: 40px;
}

.inactive {
  background-color: #2a814e !important;
}

.loaderDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fetchLoader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #2a814e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  box-shadow: 0px 0px 20px 0px #a3a2a2;
}

.sponsorContainer {
  margin-top: 20px;
  padding-bottom: 50px;
}

#fullHeight {
  height: 120vh;
}

.aboutRow {
  align-items: center;
}

.gt_switcher_wrapper {
  all: unset !important;
  position: relative !important;
  z-index: 1 !important;
}

.gt_option {
  position: absolute !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#apply-Form {
  padding: 40px;
  margin: 25px 0;
  background-color: #fff;
  color: gray;
  box-shadow: 0px 0px 7px #bebebe;
}

.form-input {
  height: 44px;
  width: 60%;
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  background: #b6b0b057;
}

.form-input::placeholder {
  color: rgb(66, 66, 68);
}

.form-input:focus {
  outline: none;
}

#full {
  width: 100%;
}

.card-col {
  margin-top: 20px !important;
}

.apply-label {
  color: #976d04;
  font-weight: 500;
}

.cryptoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clipInput {
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
  background-color: #2ebea1;
  color: #fff;
}

#copyIcon {
  padding: 7px;
  background-color: #042d2a;
  cursor: pointer;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

#or {
  text-align: center;
  font-weight: 900;
  padding: 40px 0;
  color: #2c2c2c;
}

#next-btn {
  text-align: end;
  padding: 10px 30px;
  border: none;
  background: #081439;
  font-weight: 400;
  color: white;
  border-radius: 5px;
}

.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.darkLoader {
  width: 14px;
  height: 14px;
  border: 2px solid #000000;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ============= Animations ===================== */
@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* ============= CountDown ============================ */

#countdown {
  width: 100%;
  max-width: 465px;
  height: 136px;
  text-align: center;
  background: #222;
  background-image: linear-gradient(top, #222, #333, #333, #222);
  border: 1px solid #111;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  margin: auto;
  padding: 24px 0;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#countdown:before,
#countdown:after {
  content: "";
  width: 8px;
  height: 65px;
  background: #444;
  background-image: linear-gradient(top, #555, #444, #444, #555);
  border: 1px solid #111;
  display: block;
  position: absolute;
  top: 48px;
}

#countdown:before {
  left: -10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

#countdown:after {
  right: -10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

#countdown #tiles {
  position: relative;
  z-index: 1;
}

#countdown #tiles > span {
  width: 20%;
  max-width: 92px;
  font: bold 4vw "Droid Sans", Arial, sans-serif;
  text-align: center;
  color: #111;
  background-color: #ddd;
  background-image: linear-gradient(top, #bbb, #eee);
  border-top: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
  margin: 0 2%;
  padding: 0;
  display: inline-block;
  position: relative;
}

#countdown #tiles > span:before {
  content: "";
  width: 100%;
  height: 13px;
  background: #111;
  display: block;
  padding: 0 3px;
  position: absolute;
  top: 41%;
  left: -3px;
  z-index: -1;
}

#countdown #tiles > span:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
  border-top: 1px solid #333;
  display: block;
  position: absolute;
  top: 48%;
  left: 0;
}

#countdown .labels {
  width: 100%;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: 8px;
}

#countdown .labels li {
  width: 25%;
  font: bold 2vw "Droid Sans", Arial, sans-serif;
  color: #f47321;
  text-shadow: 1px 1px 0px #000;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
}

.modal-footer {
  background-color: #000000a3 !important;
}

.miniCountDown {
  max-width: 247px !important;
  height: 128px !important;
  position: fixed !important;
  bottom: 100% !important;
  top: 90% !important;
  right: 100% !important;
  z-index: 200 !important;
}

.tile > span {
  font: bold 2vw "Droid Sans", Arial, sans-serif !important;
}

#list {
  font-size: 16px !important;
}
/* 
#logoImg {
  width: 30%;
} */

#hero-bg {
  background-image: url("./assets/heroImg.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 71%);
  text-align: center;
  text-shadow: 1px 1px 1px#000;
  padding-top: 130px;
}

.mask h1:first-child {
  color: #f24405;
}

.mask h1 {
  font-size: 45px;
  font-weight: 700;
}

#hero-btn {
  width: 30%;
  height: 3.5rem;
  border: none;
  background-color: #f28705c7;
  color: #fff;
  font-weight: 600;
  margin-top: 1.25rem;
  border-radius: 0.3rem;
}

.chartParent {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  padding: 40px 100px;
}

.butMain {
  display: flex;
  justify-content: center;
}

.buttWrappers {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}

.butclose {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayBut {
  width: 80px;
  height: 40px;
  border-radius: 7px;
  color: #ffffff;
  font-weight: 500;
  border: none;
}

#btc {
  background: #cf8512;
}

#eth {
  background-color: #504174;
}

#usdt {
  background-color: #00805a;
}

.cover {
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.partOne {
  width: 100%;
}

#kycBtn {
  padding: 8px 12px;
  border-radius: 5px;
  border: 0;
  background-color: #575b62;
  color: white;
  font-size: 14px;
}

.cheque-input {
  height: 200px;
  padding: 20px;
  border: 1px solid rgb(177, 178, 182);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: dotted;
}

.upload-cheque-btn {
  border: 1px solid gray;
  padding: 8px;
  border-radius: 8px;
  color: rgb(92, 92, 92);
  font-size: 15px;
  cursor: pointer;
}

.upload-cheque-btn:hover {
  background-color: rgb(102, 103, 105);
  color: #fff;
}

#deposit-cheque-btn {
  background-color: #0d6efd;
  color: #fff;
  border: none;
}

.imageCircle {
  border-radius: 100%;
  height: 160px;
  position: relative;
  width: 160px;
  background-color: gray;
}

.StatusButtonWrapper {
  display: flex;
  justify-content: space-around;
}

.userStatusBtn {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  border: none;
  color: white;
}

.kyc {
  padding: 6px 17px;
  background-color: #9f9f9f70;
  color: #7d7d7d;
  border-radius: 6px;
  border: 1px solid;
}
.pendingKYC {
  padding: 6px 17px;
  background-color: #fab80070;
  color: #9a660a;
  border-radius: 6px;
  border: 1px solid;
}

.verifiedKYC {
  padding: 6px 17px;
  background-color: rgb(54 250 0 / 35%);
  color: rgb(10 154 29);
  border-radius: 6px;
  border: 1px solid;
}

.rejectedKYC {
  padding: 6px 17px;
  background-color: #fa00003d;
  color: #9a0a0a;
  border-radius: 6px;
  border: 1px solid;
}

/* =============================================== */
@media screen and (max-width: 48rem) {
  #logoImg {
    width: 90%;
  }

  #hero-bg {
    height: 400px;
  }

  #banner {
    margin-top: 33px;
  }
  /* #bannerImg {
    height: 100%;
    width: 90vw;
  } */

  #bannerText1 {
    font-size: 2.125rem;
  }

  /* #aboutImg {
    height: 100%;
    width: 90vw;
  } */

  #aboutTextParagraph {
    padding: 0 10px;
  }

  .bannertRow {
    flex-direction: column !important;
  }

  .aboutRow {
    flex-direction: column-reverse !important;
  }

  .aboutSection {
    padding-top: 1.5625rem;
  }

  .plansRow {
    flex-direction: column !important;
    padding-bottom: 0;
  }

  .planCard {
    margin: 10px;
  }

  .planCard:hover {
    transform: translateY(0) scale(1);
  }

  .last {
    display: none;
  }

  #testimonyCard {
    margin: 10px 0;
    margin-bottom: 20px;
    height: 459px;
    padding: 16px;
  }

  .contactRow {
    margin: 0px;
  }

  .contactContainer {
    padding-top: 0px;
    flex-direction: column !important;
  }

  .contactInfo {
    margin-top: 20px;
    padding: 40px 20px;
  }

  #sponsors {
    /* width: 200px; */
    height: auto;
    object-fit: contain;
  }

  .popingCard {
    width: 258px;
    top: 72%;
    left: 27%;
    padding: 10px 21px;
  }

  .formHolder {
    width: 100%;
    /* height: 540px; */
    margin-bottom: 20px;
  }

  .signUp {
    width: 100%;
  }

  .profile {
    width: 100%;
  }

  .clipboardinput {
    padding: 7px;
  }

  .chooseUs {
    margin-top: 20px;
  }

  .notification {
    left: 17%;
  }

  .loggedNav {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #cryptoDetails {
    flex-direction: column !important;
  }

  #code1 {
    rotate: 61deg;
  }

  #code2 {
    rotate: 320deg;
  }
  #code3 {
    rotate: 316deg;
  }

  #code4 {
    rotate: 71deg;
  }

  #code5 {
    rotate: 349deg;
  }

  #code6 {
    rotate: 315deg;
  }

  .avatar {
    width: 151px;
    height: 151px;
  }

  .addProfile {
    bottom: 14%;
    left: 57%;
  }

  .dashboardCard {
    font-size: 14px;
  }

  #homeNavbar {
    position: fixed;
    top: 5.4%;
  }

  #contactBtn {
    background-color: #f24405;
  }

  .whyChooseUsText {
    margin-bottom: 10px;
  }

  .dynamicNavbar {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .instaLoader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }

  #or {
    padding: 0;
  }

  #apply-Form {
    padding: 40px 20px;
  }

  #countdown #tiles > span {
    padding: 15px;
    font: bold 6vw "Droid Sans", Arial, sans-serif;
  }

  .tile > span {
    font-size: 16px !important;
  }

  .miniCountDown {
    height: 143px !important;
  }

  .mask h1 {
    font-size: 30px;
    font-weight: 700;
  }

  #hero-btn {
    width: 37%;
    height: 2.5rem;
  }

  .chartParent {
    padding: 40px 30px;
  }

  .nav-grad{
    padding-left: 0px !important;
    padding-right: 0 !important;
  }
}
